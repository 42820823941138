import { convertToTera, convertToYocto } from "../utils/utils";

export const mintMonsterService = async (
  wallet, ftContract, mainContract, zmlAmount, zombieList, collectionId, alreadyBurnedIdList
) => {
  let txs = [];

  const chunkSize = 3;
  let notBurnedList = zombieList.filter((zombie) => !alreadyBurnedIdList.includes(zombie));

  for (let i = 0; i < notBurnedList.length; i += chunkSize) {
    const chunk = notBurnedList.slice(i, i + chunkSize);
    txs.push({
      receiverId: mainContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "kill_zombie_for_mint",
            args: {
              zombie_list: chunk,
            },
            gas: convertToTera("280"),
            deposit: "1"
          },
        },
      ],
    });
  }

  txs.push({
      receiverId: ftContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "ft_transfer_call",
            args: {
              receiver_id: ftContract.contractId,
              amount: convertToYocto(zmlAmount),
              msg: "ft_add_zml_reserve",
            },
            gas: convertToTera("90"),
            deposit: "1",
          },
        },
      ],
    },
    {
      receiverId: mainContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "mint_collection",
            args: {
              collection_id: Number(collectionId),
              zombie_list: zombieList,
            },
            gas: convertToTera("280"),
            deposit: convertToYocto("0.01")
          },
        },
      ],
    });

  return wallet.interface.signAndSendTransactions({transactions: txs});
};

export const mintMonsterPartService = async (
  wallet, ftContract, mainContract, zmlAmount, partsList, collectionId
) => {
  let txs = [];

  const chunkSize = 6;
  for (let i = 0; i < partsList.length; i += chunkSize) {
    const chunk = partsList.slice(i, i + chunkSize);
    txs.push({
      receiverId: mainContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "burn_monster_part_for_mint",
            args: {
              part_list: chunk,
            },
            gas: convertToTera("280"),
            deposit: "1"
          },
        },
      ],
    });
  }

  txs.push({
    receiverId: mainContract.contractId,
    actions: [
      {
        receiverId: ftContract.contractId,
        actions: [
          {
            type: "FunctionCall",
            params: {
              methodName: "ft_transfer_call",
              args: {
                receiver_id: ftContract.contractId,
                amount: convertToYocto(zmlAmount),
                msg: "ft_add_zml_reserve",
              },
              gas: convertToTera("90"),
              deposit: "1",
            },
          },
        ],
      },
      {
        type: "FunctionCall",
        params: {
          methodName: "mint_staking_collection",
          args: {
            collection_id: Number(collectionId),
            parts_list: partsList,
          },
          gas: convertToTera("280"),
          deposit: convertToYocto("0.01")
        },
      },
    ],
  });

  return wallet.interface.signAndSendTransactions({transactions: txs});
}