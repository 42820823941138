import React, { useContext, useEffect, useState } from "react";
import { Header, Footer } from "../components";
import { InnerPageHead, Loader } from "../ui";
import { get } from "../utils/api";
import { NearContext } from "../contexts/NearWallet";
import {
  Col,
  Container,
  InnerPageWrapper,
  Row,
  Wrapper,
} from "../assets/styles/common.style";

import { UserProfile } from "../components/Avatar";

const REWARDS = {
  kills: [
    75, 65, 55, 50, 40, 30, 25, 20, 15, 15, 15, 10, 10, 10, 10, 10, 5, 5, 5, 5,
    5, 5, 5, 5, 5,
  ],
};

export const ZombieKiller = () => {
  const { socialContract, currentUser } = useContext(NearContext);
  const [leaderboard, setLeaderboard] = useState([]);
  const [myStats, setMyStats] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [profiles, setProfiles] = useState({});

  const loadProfiles = async () => {
    const profileObj = {};
    const nsProfiles = await Promise.all(
      leaderboard.map(
        async (user) => await socialContract.get([`${user.userId}/profile/**`])
      )
    );
    leaderboard.map((user) => {
      let profile = nsProfiles
        .filter((p) => p !== undefined)
        .find((p) => p[user.userId]);
      profileObj[user.userId] = profile ? profile[user.userId] : undefined;
    });

    setProfiles(profileObj);
  };

  async function fetchLeaderboard() {
    setIsReady(false);
    const response = await get(
      `api/stats/zombie-kill-leaderboard/?account=${currentUser}`
    );

    if (response.data) {
      // reorder by counter
      response.data.topUsers.sort((a, b) => b._sum.counter - a._sum.counter);

      setLeaderboard(response.data.topUsers);
      setMyStats(response.data.myStats[0]);
    }

    setIsReady(true);
  }

  const firstUsersReward = (i, type) => {
    if (REWARDS[type].length > i) {
      return `${REWARDS[type][i]} USDC.e`;
    }
    return "";
  };

  useEffect(() => {
    fetchLeaderboard();
  }, []);

  useEffect(() => {
    loadProfiles();
  }, [leaderboard]);

  return (
    <InnerPageWrapper>
      <Header />
      <Wrapper>
        <Container className="flex flex-col text-white mt-6 items-center">
          <InnerPageHead
            title={"Zombie Killer"}
            description={
              "Get ready to kill zombies and win USDC tokens! Join our event from 12 Feb at 10:00 UTC to 17 Feb at 23:59 UTC. The more zombies you kill, the better your rewards. No need to worry about rarity—just focus on the total number of zombies you take down!"
            }
          />

          {isReady ? (
            <>
              <h1 className="zombie-font mt-10 text-indigo-300 font-normal md:text-4xl text-4xl capitalize">
                Total killed:{" "}
                {leaderboard
                  .map((account) => account._sum?.counter || 0)
                  .reduce((a, b) => a + b, 0)}
              </h1>

              {myStats && (
                <h1 className="zombie-font mt-10 text-indigo-300 font-normal md:text-4xl text-4xl capitalize">
                  My killed zombies: {myStats._sum?.counter || 0}
                </h1>
              )}

              {leaderboard && (
                <div className="p-5 mt-10 bg-main lg:w-3/4 w-full">
                  <Row className="border-b-2 pb-3 text-gray-500 font-bold border-gray-600/30 border-dashed">
                    <Col className="w-10">#</Col>
                    <Col className="flex-1 mr-5">Account</Col>
                    <Col className="md:w-36 w-12">Killed Zombies</Col>
                    <Col className="md:w-28 w-12">Reward</Col>
                  </Row>

                  <Col>
                    {leaderboard.map((account, i) => (
                      <Row
                        key={account.userId}
                        className={`py-3 border-b-2 border-gray-700/30 border-dashed ${i < REWARDS.kills.length ? "text-amber-500 font-semibold" : ""}`}
                      >
                        <div className="w-10">{i + 1}</div>
                        <div className="flex items-center flex-1 w-20 truncate text-ellipsis mr-5">
                          <UserProfile
                            profile={profiles}
                            accountId={account.userId}
                          />
                        </div>
                        <div className="md:w-36 w-12">
                          {account._sum.counter}
                        </div>
                        <div className="md:w-28 w-12">
                          {firstUsersReward(i, "kills")}
                        </div>
                      </Row>
                    ))}
                  </Col>
                </div>
              )}
            </>
          ) : (
            <Loader />
          )}
        </Container>
      </Wrapper>

      <Footer />
    </InnerPageWrapper>
  );
};
