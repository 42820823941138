import React, { useContext, useEffect, useState } from "react";
import { Header, Footer } from "../components";
import { InnerPageHead, Loader } from "../ui";
import { get } from "../utils/api";
import { NearContext } from "../contexts/NearWallet";
import {
  Col,
  Container,
  InnerPageWrapper,
  Row,
  Wrapper,
} from "../assets/styles/common.style";

import { UserProfile } from "../components/Avatar";

const REWARDS = {
  kills: [
    200,150,100,75,60,50,45,40,35,30,25,25,20,20,20,15,15,15,10,10,10,10,10,10
  ],
};

export const MonsterKiller = () => {
  const { socialContract, currentUser } = useContext(NearContext);
  const [leaderboard, setLeaderboard] = useState([]);
  const [myStats, setMyStats] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [profiles, setProfiles] = useState({});

  const loadProfiles = async () => {
    const profileObj = {};
    const nsProfiles = await Promise.all(
      leaderboard.map(
        async (user) => await socialContract.get([`${user.userId}/profile/**`])
      )
    );
    leaderboard.map((user) => {
      let profile = nsProfiles
        .filter((p) => p !== undefined)
        .find((p) => p[user.userId]);
      profileObj[user.userId] = profile ? profile[user.userId] : undefined;
    });

    setProfiles(profileObj);
  };

  async function fetchLeaderboard() {
    setIsReady(false);
    const response = await get(
      `api/stats/monster-kill-leaderboard/?account=${currentUser}`
    );

    if (response.data) {
      // reorder by counter
      response.data.topUsers.sort((a, b) => b._sum.counter - a._sum.counter);

      setLeaderboard(response.data.topUsers);
      setMyStats(response.data.myStats[0]);
    }

    setIsReady(true);
  }

  const firstUsersReward = (i, type) => {
    if (REWARDS[type].length > i) {
      return `${REWARDS[type][i]} USDC.e`;
    }
    return "";
  };

  useEffect(() => {
    fetchLeaderboard();
  }, []);

  useEffect(() => {
    loadProfiles();
  }, [leaderboard]);

  return (
    <InnerPageWrapper>
      <Header />
      <Wrapper>
        <Container className="flex flex-col text-white mt-6 items-center">
          <InnerPageHead
            title={"Monster Killer"}
            description={
              "Get ready to kill monsters and win USDC tokens! Join our event from 19 May at 00:00 UTC to 23 May at 23:59 UTC. The more and stronger monsters you kill, the better your rewards*."
            }
          />

          {isReady ? (
            <>
              {myStats && (
                <h1 className="zombie-font mt-10 text-indigo-300 font-normal md:text-4xl text-4xl capitalize">
                  My Points: {myStats._sum?.counter || 0}
                </h1>
              )}

              {leaderboard && (
                <div className="p-5 mt-10 bg-main lg:w-3/4 w-full">
                  <Row className="border-b-2 pb-3 text-gray-500 font-bold border-gray-600/30 border-dashed">
                    <Col className="w-10">#</Col>
                    <Col className="flex-1 mr-5">Account</Col>
                    <Col className="md:w-36 w-12">Points</Col>
                    <Col className="md:w-28 w-12">Reward</Col>
                  </Row>

                  <Col>
                    {leaderboard.map((account, i) => (
                      <Row
                        key={account.userId}
                        className={`py-3 border-b-2 border-gray-700/30 border-dashed ${i < REWARDS.kills.length ? "text-amber-500 font-semibold" : ""}`}
                      >
                        <div className="w-10">{i + 1}</div>
                        <div className="flex items-center flex-1 w-20 truncate text-ellipsis mr-5">
                          <UserProfile
                            profile={profiles}
                            accountId={account.userId}
                          />
                        </div>
                        <div className="md:w-36 w-12">
                          {account._sum.counter}
                        </div>
                        <div className="md:w-28 w-12">
                          {firstUsersReward(i, "kills")}
                        </div>
                      </Row>
                    ))}
                  </Col>
                </div>
              )}

              <div className={"w-3/4 xl:w-1/2 mx-auto mb-6 mt-4 text-left text-sm"}>
                <p>*We calculate Points based on the total power of the monsters you defeat and multiply it by their rarity.</p>
                <p className={"mt-4"}>The multipliers are as follows:
                  <ul>
                    <li>- Common: x1</li>
                    <li>- UnCommon: x2</li>
                    <li>- Rare: x3</li>
                    <li>- Epic: x5</li>
                  </ul>
                </p>
                <p className={"mt-4"}><b className={"text-base"}>Example:</b> <br />
                  Kill <b>Rare(x3)</b> monster with <b>100</b> health, <b>50</b> attack, <b>50</b> brain. <br />
                  Total: (100+50+50)*3 = 600 points</p>

              </div>
            </>
          ) : (
            <Loader />
          )}
        </Container>
      </Wrapper>

      <Footer />
    </InnerPageWrapper>
  );
};
